import {
    Home as HomeIcon,
    TrendingUp as SharedReportsIcon,
    Face as Accounts,
    Tune as Adjustments,
    Receipt,
    CreditCard as Expense,
    Widgets as ProductsIcon,
    Book as AccountingIcon,
    GroupOutlined as Members,
    SettingsApplicationsOutlined as Settings,
    Assessment,
    Functions as AccountingCentral,
    AccountBalance,
    GridOn,
    LibraryBooksOutlined,
    LockOutlined,
    MonetizationOn,
    Money,
    People,
    SupervisorAccount,
    TrendingUp,
    TrendingDown,
    ViewComfyOutlined
} from "@material-ui/icons";

import SyncIcon from "@material-ui/icons/Sync";
import SettingsIcon from "@material-ui/icons/Settings";
import AdminIcon from "@material-ui/icons/SupervisorAccount";
import React from "react";
import { orgId, getOrg } from "./utils/auth";

const isAdjustmentsEnabled = (orgConfig) => {
    if (orgConfig) {
        const adjustments = orgConfig.find(
            (cfg) => cfg.id == "properties/manualAdjustmentsEnabled"
        );

        return adjustments && adjustments.value == true;
    }

    return false;
};

const accountingMenu = (props) => {
    const toReturn = [
        {
            title: "Journals",
            icon: <Assessment />,
            getHref: () => `/${orgId()}/journalaccounts`,
            visible: (org) => (org ? true : false)
        },
        {
            title: "Adjustments",
            icon: <Adjustments />,
            getHref: () => `/${orgId()}/adjustments`,
            visible: (org) => (org ? true : false)
        }
    ];

    return toReturn;
};

const settingsMenu = (props) => {
    const toReturn = [
        {
            title: "Configure RevRec",
            getHref: () => `/${orgId()}/organization/settings?tab=1`,
            visible: (org) => (org ? true : false)
        },
        {
            title: "Accounting Rules",
            getHref: () => `/${orgId()}/esp?tab=0`,
            visible: (org) => (org ? true : false)
        },
        {
            title: "Team Members",
            getHref: () => `/${orgId()}/organization?tab=0`,
            visible: (org) => (org ? true : false)
        }
    ];

    return toReturn;
};

const adminMenu = (props) => {
    return [
        {
            title: "Merchants",
            getHref: () => `/admin`,
            visible: (org, props) => {
                const { currentUser } = props;
                return currentUser && currentUser.superAdminLevel > 0;
            }
        },
        {
            title: "Processes",
            getHref: () => `/admin/processes`,
            visible: (org, props) => {
                const { currentUser } = props;
                return currentUser && currentUser.superAdminLevel > 0;
            }
        },
        {
            title: "Admin Actions",
            getHref: () => `/admin/action`,
            visible: (org, props) => {
                const { currentUser } = props;
                return currentUser && currentUser.superAdminLevel > 0;
            }
        }
    ];
};

export const _menuItems = [
    {
        title: "Home",
        icon: <HomeIcon />,
        getHref: () => `/${orgId()}/dashboards`,
        visible: (org) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false
    },

    {
        title: "Customers",
        icon: <Accounts />,
        getHref: () => `/${orgId()}/pages/customers`,
        visible: (org) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false
    },
    {
        title: "Sales Order",
        icon: <Receipt />,
        getHref: () => `/${orgId()}/pages/sales`,
        visible: (org) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false
    },
    {
        title: "Expense",
        icon: <Expense />,
        getHref: () => `/${orgId()}/pages/expenses`,
        visible: (org, props) => {
            const config =
                props.orgConfig &&
                props.orgConfig.find(
                    (cfg) => cfg.id == "properties/expense-enabled"
                );
            return orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org && config && config.value == true
                ? true
                : false;
        }
    },
    {
        title: "Products",
        icon: <ProductsIcon />,
        getHref: () => `/${orgId()}/products`,
        visible: (org) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false
    },
    {
        title: "Accounting",
        icon: <AccountingIcon />,
        getHref: () => `/${orgId()}/journalaccounts`,
        visible: (org, props) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org && !isAdjustmentsEnabled(props.orgConfig)
                ? true
                : false
    },
    {
        title: "Accounting",
        icon: <AccountingIcon />,
        visible: (org, props) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org && isAdjustmentsEnabled(props.orgConfig)
                ? true
                : false,
        createChildren: accountingMenu,
        submenuStyles: { top: "250px" }
    },
    {
        title: "Sync",
        icon: <SyncIcon />,
        getHref: () => `/${orgId()}/pages/sync`,
        visible: (org) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false
    },
    {
        title: "Reports",
        icon: <SharedReportsIcon />,
        getHref: () => `/${orgId()}/mstr-native-report-parent`,
        visible: (org, props) => {
            if (org && !org.disableReporting) {
                return true;
            }

            return !org.disableReporting;
        }
    },
    {
        title: "Settings",
        icon: <SettingsIcon />,
        visible: (org, props) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? props && props.clientTables && props.clientTables.length > 0
                    ? false
                    : true
                : false,
        createChildren: settingsMenu,
        submenuStyles: { top: "351px" }
    },
    {
        title: "Admin",
        icon: <AdminIcon />,
        visible: (org, props) => {
            const { currentUser } = props;
            return currentUser && currentUser.superAdminLevel > 0;
        },
        createChildren: adminMenu,
        submenuStyles: { top: "351px" }
    }
];

/** Accounting Central menu items */
const acAccountingMenu = (props) => {
    const toReturn = [
        {
            title: "Trial Balance",
            icon: <AccountBalance />,
            getHref: () => `/ac/${orgId()}/trialbalance`,
            visible: (org) =>
                orgId().startsWith("7") || orgId().startsWith("6")
                    ? false
                    : org
                    ? true
                    : false
        },
        {
            title: "Journal Entries",
            icon: <ViewComfyOutlined />,
            getHref: () => `/ac/${orgId()}/journal-entries`,
            visible: (org) =>
                orgId().startsWith("7") || orgId().startsWith("6")
                    ? false
                    : org
                    ? true
                    : false
        },
        {
            title: "Post Journals",
            icon: <Assessment />,
            getHref: () => `/ac/${orgId()}/journal-posting`,
            visible: (org) => (org ? true : false)
        },
        {
            title: "Close Period",
            icon: <LockOutlined />,
            getHref: () => `/ac/${orgId()}/period-close`,
            visible: (org) => (org ? true : false)
        }
    ];

    return toReturn;
};

const acReportsMenu = (props) => {
    const toReturn = [
        {
            title: "Billing Transaction Recon",
            icon: <GridOn />,
            getHref: () => `/ac/${orgId()}/billing-period-recon`,
            visible: (org) => (org ? true : false)
        },
        {
            title: "Payment Transaction Recon",
            icon: <GridOn />,
            getHref: () => `/ac/${orgId()}/transaction-period-recon`,
            visible: (org) => (org ? true : false)
        }
    ];

    return toReturn;
};

const acConfigurationMenu = (props) => {
    const toReturn = [
        {
            title: "Accounting",
            icon: <LibraryBooksOutlined />,
            getHref: () => `/ac/${orgId()}/accounting-settings`,
            visible: (org) => (org ? true : false)
        },
        {
            title: "Team Members",
            icon: <SupervisorAccount />,
            getHref: () => `/ac/${orgId()}/team`,
            visible: (org) => (org ? true : false)
        }
    ];

    return toReturn;
};

export const acMenuItems = [
    {
        title: "Home",
        icon: <HomeIcon />,
        getHref: () => `/ac/${orgId()}`,
        visible: (org) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false
    },
    {
        title: "Customers",
        icon: <People />,
        getHref: () => `/ac/${orgId()}/customers`,
        visible: (org) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false
    },
    {
        title: "Accounts Receivable",
        icon: <Receipt />,
        getHref: () => `/ac/${orgId()}/accountsReceivable`,
        visible: (org) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false
    },
    {
        title: "Payments",
        icon: <MonetizationOn />,
        getHref: () => `/ac/${orgId()}/cash`,
        visible: (org) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false
    },
    /*
    {
        title: "Revenue",
        icon: <TrendingUp />,
        getHref: () => `/ac/${orgId()}/revenue`,
        visible: (org, props) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false
    },
    {
        title: "Expense",
        icon: <GridOn />,
        getHref: () => `/ac/${orgId()}/expense`,
        visible: (org, props) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false
    },
    {
        title: "Tax Payable",
        icon: <Money />,
        getHref: () => `/ac/${orgId()}/tax-payable`,
        visible: (org) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false
    },
    {
        title: "Bad Debt",
        icon: <TrendingDown />,
        getHref: () => `/ac/${orgId()}/debt`,
        visible: (org) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false
    },
    */
    {
        title: "Accounting",
        icon: <AccountingIcon />,
        visible: (org) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false,
        createChildren: acAccountingMenu,
        submenuStyles: { top: "415px" }
    },
    {
        title: "Reports",
        icon: <SharedReportsIcon />,
        visible: (org, props) => {
            if (org && !org.disableReporting) {
                return true;
            }

            return !org.disableReporting;
        },
        createChildren: acReportsMenu,
        submenuStyles: { top: "510px" }
    },
    {
        title: "Settings",
        icon: <SettingsIcon />,
        visible: (org) =>
            orgId().startsWith("7") || orgId().startsWith("6")
                ? false
                : org
                ? true
                : false,
        createChildren: acConfigurationMenu,
        submenuStyles: { top: "605px" }
    }
];

export const getMenuItems = (menuItems, props = {}) => {
    let items = menuItems;

    if (!items) {
        items = _menuItems;

        if (props.space && props.space == "ac") {
            items = acMenuItems;
        }
    }

    return items.map((item) => {
        if (item.visible(getOrg(), props)) {
            item.href = item.getHref && item.getHref();

            if (item.children) {
                item.children =
                    item.children && getMenuItems(item.children, props);
            } else if (item.createChildren) {
                item.children = getMenuItems(item.createChildren(props), props);
            }
        }

        return item;
    });
};

export const routeItems = [
    {
        title: "Profile",
        href: "/profile"
    },
    {
        title: "Dashboard",
        href: "/dashboards"
    }
];
